import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { ISlice, IOnOffType } from "../../types/"

// Styling
import { mq } from "../../styles/utils/mq"
import colors from "../../styles/colors"
import textStyles from "../../styles/textStyles"

// Utils
import PageContext from "../../utils/context/PageContext"

// Components
import { renderSlice } from "../../components/helpers/slice"
import { Row, Col } from "../../components/layout/Grid"
import SEO from "../../components/layout/SEO"
import Container from "../../components/layout/Container"
import Divider from "../../components/layout/Divider"
import AnimatedScrollWrapper from "../../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../../components/animation/AnimatedTextScrollWrapper"
import SocialBlockComponent from "../../components/SocialBlock"
import TryUs from "../../components/TryUs"

// Config
import { config as pageTransitionsConfig } from "../../components/pageTransitions/config"

interface IProps {
  data: {
    metaTitle?: string
    metaDescription?: string
    metaImage?: {
      url: string
    }
    title: string[]
    headline?: string
    publicationType: string
    publicationDate: string
    reading_time: string
    author: {
      name: string
      job_title: string
    }
  }
  slices: ISlice[]
}

const BlogTemplate = ({ data, slices }: IProps) => {
  const page = useContext(PageContext)
  const {
    title,
    headline,
    publicationDate,
    publicationType,
    reading_time,
    author,
  } = data

  useEffect(() => {
    page.updateColor(colors.white)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO
        title={`${data.metaTitle || title} - ${publicationType}`}
        description={data.metaDescription}
        image={data.metaImage && data.metaImage.url}
      />

      <Header>
        <Container>
          <Row>
            {publicationDate && (
              <PublicationDate>
                <AnimatedScrollWrapper>
                  &mdash; {publicationDate}
                </AnimatedScrollWrapper>
              </PublicationDate>
            )}

            <Col m={8} mOffset={2}>
              {title && title.length && (
                <Title>
                  <AnimatedTextScrollWrapper
                    text={title}
                    textStyles={textStyles.headingL}
                  />
                </Title>
              )}

              {headline && (
                <Headline>
                  <AnimatedScrollWrapper>{headline}</AnimatedScrollWrapper>
                </Headline>
              )}
            </Col>

            <Col m={3} mOffset={1}>
              {publicationType && author && (
                <PublicationMetadata>
                  <AnimatedScrollWrapper>
                    &mdash; {publicationType}
                    <br />
                    By {author.name}
                    {author.job_title !== null && (
                      <JobTitle>{author.job_title}</JobTitle>
                    )}
                    {reading_time !== null && (
                      <ReadingTime>Reading time: {reading_time}</ReadingTime>
                    )}
                  </AnimatedScrollWrapper>
                </PublicationMetadata>
              )}
            </Col>
          </Row>

          <Row>
            <Col m={12} mOffset={2}>
              <AnimatedScrollWrapper>
                <Divider color={colors.black} />
              </AnimatedScrollWrapper>
            </Col>
          </Row>
        </Container>
      </Header>

      {slices &&
        slices.length &&
        slices.map((slice: ISlice) => {
          const hasSpacing = slice.data.hasSpacing

          const props = {
            // Assign a default
            isSpaced: "off" as IOnOffType,
            // Override if condition passes
            ...(hasSpacing === "on" && {
              isSpaced: slice.data.hasSpacing,
            }),
          }

          return (
            <Section key={slice.id} {...props}>
              {renderSlice(slice)}
            </Section>
          )
        })}
      <Row>
        <Col m={12} mOffset={2}>
          <StyledTryUs
            page={page}
            title="Experience the difference!"
            linkLabel="Get in touch!"
            blockTheme="accent"
            transparent={true}
          />
        </Col>
      </Row>
      <SocialBlock theme="light" />
    </Wrapper>
  )
}

// Layout styles
const Section = styled.section<{ isSpaced?: IOnOffType }>`
  margin-bottom: ${(props: { isSpaced?: IOnOffType }) =>
    props.isSpaced === "on" ? "var(--base-column-size)" : 0};

  &:last-child {
    margin-bottom: 0;
  }
`

// Main styles
const Wrapper = styled.div`
  color: ${colors.black};
`

// Header
const Header = styled.header`
  position: relative;
  margin-bottom: var(--base-column-size);
`

const PublicationDate = styled.div`
  margin-bottom: 8px;

  ${mq.from.M`
    position: absolute;
    top: 8px;
    left: var(--base-column-size);
    width: 25vh;
    height: var(--base-column-size);
    margin-bottom: 0;
    text-align: right;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  `}
`

const Title = styled.div`
  margin-bottom: calc(var(--base-column-size) / 4);
`

const Headline = styled.h3`
  ${textStyles.headingS};
  margin-bottom: calc(var(--base-column-size) / 4);
  color: ${colors.accent1};
`

const PublicationMetadata = styled.div`
  margin-bottom: 16px;

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const ReadingTime = styled.p`
  margin-top: 15px;
`

const JobTitle = styled.p`
  font-style: italic;
`

const SocialBlock = styled(SocialBlockComponent)`
  background-color: ${colors.lGrey};
`
const StyledTryUs = styled(TryUs)`
  margin-top: 150px;
`

export default BlogTemplate
