import React from "react"
import { graphql } from "gatsby"

// Utils
import {
  formatPrismicData,
  getTextLines,
} from "../../utils/helpers/prismicHelpers"

// Components
import BlogTemplate from "./blogTemplateView"

const BlogTemplateContainer = ({ data }: any) => {
  if (!data.blogPost) {
    return
  }

  let slices
  const rawData = data.blogPost.data
  const rawAuthorData = data.blogPost.data.author.document[0].data

  const formattedData = {
    metaTitle: rawData.metaTitle,
    metaDescription: rawData.metaDescription,
    metaImage: rawData.metaImage,
    title: getTextLines(rawData.title.raw),
    headline: rawData.headline,
    publicationType: rawData.publicationType,
    publicationDate: rawData.publicationDate,
    reading_time: rawData.reading_time,
    author: {
      name: rawAuthorData.name,
      job_title: rawAuthorData.job_title
    },
  }

  if (rawData.slices) {
    slices = rawData.slices
      .filter((slice: any) => slice.type && slice)
      .map((slice: any) => formatPrismicData(slice))
  }

  return <BlogTemplate data={formattedData} slices={slices} />
}

export const query = graphql`
  query GetBlog($slug: String!) {
    blogPost: prismicBlogPost(uid: { eq: $slug }) {
      slug: uid
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        metaImage: meta_image {
          url
        }
        title {
          raw {
            text
          }
        }
        headline
        publicationType: post_type
        publicationDate: publication_date(formatString: "DD MMMM YYYY")
        reading_time
        author {
          document {
            data {
              name
              job_title
            }
          }
        }
        slices: body {
          ... on PrismicBlogPostBodyHeading {
            type: slice_type
            id
            primary {
              heading_text
            }
          }
          ... on PrismicBlogPostBodyRichText {
            type: slice_type
            id
            primary {
              richtext_text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            type: slice_type
            id
            primary {
              image {
                alt
                url
              }
              image_size
            }
          }
          ... on PrismicBlogPostBodyTextMedia {
            type: slice_type
            id
            primary {
              text_media_title {
                raw {
                  text
                }
              }
              text_media_copy {
                html
              }
              text_media_media {
                kind
                url
                alt: name
              }
              text_media_media_alignment
              text_media_media_padding
              text_media_has_spacing
            }
          }
          ... on PrismicBlogPostBodyQuote {
            type: slice_type
            id
            primary {
              quote_copy {
                html
              }
              quote_author
              quote_size
            }
          }
          ... on PrismicBlogPostBodyPrincipleHeading {
            type: slice_type
            id
            primary {
              heading_title {
                text
              }
              number
              main_image {
                url
              }
              background_image {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`

export default BlogTemplateContainer
